
  import { defineComponent } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { EffectError } from 'effector';
  import { redirectUri } from '@/helpers/googleAuth';
  import { makeAuth, AuthError } from '@/store/auth';

  export default defineComponent({
    name: 'SignInOauth',
    components: {},
    setup: () => {
      const { t } = useI18n();
      const router = useRouter();
      const route = useRoute();
      (async () => {
        const search = route.fullPath.substring(route.fullPath.indexOf('?'));
        const urlParams = new URLSearchParams(search);
        const params: Record<string, string> = {};
        urlParams.forEach((v, k) => (params[k] = v));
        try {
          await makeAuth({ code: params.code, redirectUri });
          // eslint-disable-next-line no-debugger
          const conference = sessionStorage.getItem('backLink');
          if (conference) {
            await router.replace({
              name: 'Join',
              params: {
                conference,
              },
            });
          } else {
            await router.replace({
              name: 'Signin',
            });
          }
        } catch (err) {
          const isAuthError = (error: unknown): error is AuthError =>
            typeof error === 'object' && error !== null && 'error' in error && 'status' in error;

          if (isAuthError(err)) {
            await router.replace({
              name: 'Signin',
              params: {
                authErrorCode: err.status,
              },
            });
          } else {
            await router.replace({
              name: 'Signin',
            });
          }
        }
      })();

      return {
        t,
      };
    },
  });
